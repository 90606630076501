require("./src/styles/bootstrap/css/bootstrap.min.css");
const ReactGA  = require("react-ga");
ReactGA.initialize('G-M71N5PECEV', {
    gaOptions: {
        useExistingGa: true
    }
});

// exports.onClientEntry = (() => {
//     if (window.localStorage.getItem('pbpTrackingPrompt') === 'accepted') {
//         window.GAInitialised = true;
//         ReactGA.pageview(location.pathname);
//     } 
// })

// exports.onRouteUpdate = ({ location, prevLocation }) => {
//     // If localStorage item was manually removed from browser
//     if (window.localStorage.getItem('pbpTrackingPrompt') === 'accepted') {
//         if (!window.GAInitialised) {
//             ReactGA.pageview(location.pathname);
//         }
//     }
// }